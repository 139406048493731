<template>
  <div class="useral-insert-court">
    <div class="insert-court-fields">
      <div class="select-user-invoice logo-input">
        <custom-file-input :label="$t('courts.labels.image')"
                           :is-empty="true"
                           :classes="{'preview-image': true}"
                           logo-type="scoreCategory"
                           :styles="{width: '100%'}"
                           v-model="court.logo"
                           :disabled="false"/>

      </div>

      <custom-validate-input :label="$t('courts.labels.name')"
                             :classes="{'w-full': true}"
                             align="right"
                             rtl
                             v-model="court.name"/>
    </div>

    <vs-button v-show="false"
               id="insertCourtBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput.vue'
  import CustomFileInput from "../../../../../components/customInput/customeFileInput.vue";
  import {insertCourt} from '../../../../../http/requests/club/courts'
  import {checkUserPermissions} from '../../../../../assets/js/functions'

  export default {
    name: 'InsertCourt',
    components: {
      CustomFileInput,
      CustomValidateInput
    },
    props: {
      courtId: 0
    },
    data() {
      return {
        courtTypes: [
          {
            id: 2,
            label: this.$t('courts.types.ground'),
            value: 2
          },
          {
            id: 1,
            label: this.$t('courts.types.hard'),
            value: 1
          },
          {
            id: 3,
            label: this.$t('courts.types.grass'),
            value: 3
          }
        ],
        court: {
          name: {
            value: '',
            isValid: false
          },
          type: {
            label: '-',
            id: 2
          },
          logo: {}
        },
        roles: []
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (!this.court.name.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('courts.validators.name'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        } else if (this.court.type.id === -1) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('courts.validators.type'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const court = {
          name: this.court.name.value,
          type: parseInt(this.court.type.id) > -1 ? this.court.type.id : null,
          avatar: this.court.logo.value ? this.court.logo.value : null
        }

        if (this.court.role && this.court.role.value > 0 && this.courtId !== this.$store.state.auth.court.id) court.role_id = this.court.role.value

        insertCourt(court).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('courts.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': this.$t('courts.notifications.parseError.name'),
              'type': this.$t('courts.notifications.parseError.type'),
              'avatar': this.$t('courts.notifications.parseError.image'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('courts.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-court {
    width: 100%;

    .insert-court-fields {
      .logo-input {
        min-height: 100px;
      }

      .custom-profile-image-input {
        label {
          height: 110px;
          width: 110px;
        }
      }

      .custom-input:last-child {
        margin-bottom: 0;
      }

      .input-field-item {
        position: relative;
        border: 1px solid #cdcdcd;
        border-radius: 0.4rem;
        padding: 5px 10px;
        margin: 15px 0;
        display: flex;
        justify-content: space-around;
        min-height: 35px;

        &.invalid {
          border-color: #b00000;

          .input-field-label {
            color: #b00000;
          }
        }

        .input-field-label {
          position: absolute;
          font-size: 12px;
          top: -10px;
          left: 8px;
          background-color: #ffffff;
          padding: 0 5px;
        }

        .radio-item {
          display: flex;
          align-items: center;
          direction: rtl;

          label {
            margin-left: 5px;
          }
        }
      }
    }
  }
</style>
